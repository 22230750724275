.customPcModal {
  @apply relative w-[750px] border-[16px] rounded-[32px] p-[32px] bg-[#EEE2C4] border-[#9A531F] text-center text-[#953D22] max-h-[60rem];

  :global {
    .ant-modal-header,
    .ant-modal-footer {
      @apply hidden;
    }
    .ant-modal-content {
      @apply h-full bg-inherit shadow-none;
      .ant-modal-close {
        @apply md:w-[48px] md:h-[48px] absolute right-[-18Px] top-[-18Px];
        .ant-modal-close-x {
          @apply w-full h-full;
        }
      }
    }
    .ant-modal-body {
      @apply w-full p-0;
    }
  }
}

.mobileCustomModal {
  @apply relative !w-[358px] md:!w-[750px] border-[8px]  p-[16px] rounded-[16px] bg-[#EEE2C4] border-[#9A531F] text-center text-[#953D22] max-h-[70vh];

  :global {
    .ant-modal-header,
    .ant-modal-footer {
      @apply hidden;
    }
    .ant-modal-content {
      @apply h-full bg-inherit shadow-none;
      .ant-modal-close {
        @apply md:w-[48px] md:h-[48px] absolute right-[-8px] top-[-8px];
        .ant-modal-close-x {
          @apply w-full h-full;
        }
      }
    }
    .ant-modal-body {
      @apply w-full p-0;
    }
  }
}
